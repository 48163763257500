<template>
  <div class="flex justify-between">
    <div v-for="difficulty in difficulties" :key="difficulty.id">
      <DificultadItem
        :level="difficulty.id"
        :unselected="dificultadSeleccionada && dificultadSeleccionada.id !== difficulty.id"
        @selected="setSelected(difficulty)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import DificultadItem from '@/components/dificulty/DificultadItem.vue'

export default {
  name: 'SeleccionDificultad',
  components: { DificultadItem },

  computed: {
    ...mapGetters({ 
      difficulties: 'difficulties/difficulties',
      dificultadSeleccionada: 'ingresoTrabajo/dificultad' 
    })
  },

  async mounted() {
    if (!this.difficulties) {
      await this.getDifficulties()
    }
  },

  methods: {
    ...mapActions({ getDifficulties: 'difficulties/getDifficulties' }),
    ...mapMutations({ setDificultad: 'ingresoTrabajo/setDificultad' }),

    setSelected(difficulty) {
      this.setDificultad(difficulty)
    }
  }
}
</script>
