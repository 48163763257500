<template>
  <div class="relative">
    <ArrowButton
      :text="clienteSeleccionado ? clienteSeleccionado.name : 'cliente'"
      :rotate="(folded ? '' : '-') + '90deg'"
      background="white"
      color="black"
      @onClick="toggleFolded"
    />
    <div v-if="!folded" class="flex flex-col w-full absolute top-11 z-10">
      <div
        v-for="cliente in clients"
        :key="cliente.id"
        class="custom-list-item cursor-pointer"
        @click="selectCliente(cliente)"
      >
        {{ cliente.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ArrowButton from '@/components/shared/ArrowButton.vue'

export default {
  name: 'SeleccionCliente',
  components: { ArrowButton },
  data() {
    return {
      folded: true
    }
  },

  computed: {
    ...mapGetters({ 
      clienteSeleccionado: 'ingresoTrabajo/cliente',
      clients: 'clients/clients'
    })
  },

  async mounted() {
    if (!this.clients ){
      await this.getClients()
    }
  },

  methods: {
    ...mapActions({getClients: 'clients/getClients'}),
    ...mapMutations({ setCliente: 'ingresoTrabajo/setCliente' }),

    selectCliente(cliente) {
      this.setCliente(cliente)
      this.toggleFolded()
    },

    toggleFolded() {
      this.folded = !this.folded
    }
  }
}
</script>
