<template>
  <div class="relative">
    <ArrowButton
      :text="label"
      :rotate="(folded ? '' : '-') + '90deg'"
      :background="deadline ? 'white' : 'black'"
      :color="deadline ? 'black' : 'white'"
      @onClick="toggleFolded"
    />
    <div v-if="!folded" class="w-full absolute top-11 z-10">
      <DatePicker
        v-model="date"
        is-expanded
        is-dark
        color="red"
        locale="es"
        :attributes="attrs"
        :min-date="new Date()"
        :first-day-of-week="1"
        @dayclick="onDayClick"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { mapGetters, mapMutations } from 'vuex'
import ArrowButton from '@/components/shared/ArrowButton.vue'

export default {
  name: 'SeleccionDeadline',
  components: { ArrowButton, DatePicker },
  data() {
    return {
      date: null,
      folded: true,
      attrs: [
        {
          key: 'today',
          dot: true,
          dates: new Date()
        }
      ]
    }
  },

  computed: {
    ...mapGetters({ deadline: 'ingresoTrabajo/deadline' }),

    label() {
      return this.deadline ? this.deadline.toLocaleDateString() : 'deadline'
    }
  },

  mounted() {
    this.date = this.deadline
  },

  methods: {
    ...mapMutations({ setDeadline: 'ingresoTrabajo/setDeadline' }),

    onDayClick(day) {
      if (!day.isDisabled) {
        this.setDeadline(day.date)
        this.folded = true
      }
    },

    toggleFolded() {
      this.folded = !this.folded
    }
  }
}
</script>
