<template>
  <div class="relative">
    <ArrowButton
      :text="tipoSeleccionado ? tipoSeleccionado.description : 'tipo'"
      :rotate="(folded ? '' : '-') + '90deg'"
      :background="tipoSeleccionado ? 'white' : 'black'"
      :color="tipoSeleccionado ? 'black' : 'white'"
      @onClick="toggleFolded"
    />
    <div v-if="!folded" class="flex flex-col w-full absolute top-11 z-10">
      <div v-for="workType in workTypes" :key="workType.id" class="custom-list-item cursor-pointer" @click="selectTipo(workType)">
        {{ workType.description }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import ArrowButton from '@/components/shared/ArrowButton.vue'

export default {
  name: 'SeleccionTipo',
  components: { ArrowButton },
  data() {
    return {
      folded: true
    }
  },

  computed: {
    ...mapGetters({ 
      workTypes: 'workTypes/workTypes',
      tipoSeleccionado: 'ingresoTrabajo/tipo' 
    })
  },

  async mounted() {
    if (!this.workTypes) {
      await this.getWorkTypes()
    }
  },

  methods: {
    ...mapActions({
      getWorkTypes: 'workTypes/getWorkTypes'
    }),

    ...mapMutations({ setTipo: 'ingresoTrabajo/setTipo' }),

    selectTipo(tipo) {
      this.setTipo(tipo)
      this.toggleFolded()
    },

    toggleFolded() {
      this.folded = !this.folded
    }
  }
}
</script>
