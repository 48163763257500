<template>
  <div>
    <div
      v-if="!loading"
      class="main-container flex flex-col px-3 py-1"
      :class="{ filled: isFilled }"
    >
      <div class="input-container first">
        <input
          v-model="palabraClave"
          type="text"
          placeholder="Palabra clave 1"
          name="palabra-clabe"
          @input="set('palabraClave')"
        />
      </div>
      <div class="input-container">
        <input
          v-model="subPalabraClave"
          type="text"
          placeholder="Sub-palabra clave"
          name="sub-palabra-clabe"
          @input="set('subPalabraClave')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'SeleccionPalabrasClave',
  data() {
    return {
      loading: true,
      palabraClave:'',
      subPalabraClave:''
    }
  },

  computed: {
    ...mapGetters({ 
      statepalabraClave: 'ingresoTrabajo/palabraClave',
      statesubPalabraClave: 'ingresoTrabajo/subPalabraClave',
    }),

    isFilled() {
      return this.palabraClave && this.subPalabraClave
    }
  },

  mounted() {
    this.palabraClave = this.statepalabraClave
    this.subPalabraClave = this.statesubPalabraClave
    this.loading = false
  },

  methods: {
    ...mapMutations({ setPalabraClave: 'ingresoTrabajo/setPalabraClave' }),

    set(property) {
      const value = this[property]
      this.setPalabraClave({ property, value })
    }
  }
}
</script>

<style scoped>
.main-container {
  background-color: black;
}
.filled.main-container {
  background-color: white;
}

.input-container.first {
  border-bottom: 1px solid white;
}
.filled .input-container.first {
  border-color: black;
}

input {
  @apply w-full text-sm bg-transparent text-white font-bold px-1 py-2;
  font-family: ProximaNovaBold;
}
.filled input {
  @apply text-black;
}

input::placeholder {
  @apply text-xs;
  font-family: ProximaNovaLightItalic;
}
</style>
