<template>
  <div class="flex flex-col gap-1">
    <BackButton class="mb-1" :route="{ name: 'Director'}" />
    <SeleccionRecurso :recurso="recurso" store="ingresoTrabajo" class="mb-1" />
    <div v-if="recurso" class="flex flex-col gap-2">
      <SeleccionCliente />
      <SeleccionPalabrasClave v-if="cliente" />
      <SeleccionDificultad v-if="palabraClave && subPalabraClave" class="my-1" />
      <SeleccionDeadline v-if="dificultad" />
      <SeleccionAdmin v-if="deadline" :admin="admin" store="ingresoTrabajo" />
      <SeleccionTipo v-if="admin" />

      <SubmitButton v-if="tipo" text="ingresar" background="#b3b3b3" color="#000" :disabled="loading || !formIsFilled" @onClick="submitIngresoTrabajo" />
    </div>

    <BlackModal
      v-if="showSuccessModal"
      text="Ingreso Correcto"
      button-text="IR AL MAIL"
      @confirm="finalize"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import BlackModal from '@/components/shared/BlackModal.vue'
import BackButton from '@/components/shared/BackButton.vue'
import SubmitButton from '@/components/shared/SubmitButton.vue'
import SeleccionRecurso from '@/components/director/SeleccionRecurso.vue'
import SeleccionAdmin from '@/components/director/SeleccionAdmin.vue'
import SeleccionCliente from '@/components/director/ingreso-trabajo/SeleccionCliente.vue'
import SeleccionPalabrasClave from '@/components/director/ingreso-trabajo/SeleccionPalabrasClave.vue'
import SeleccionDificultad from '@/components/director/ingreso-trabajo/SeleccionDificultad.vue'
import SeleccionTipo from '@/components/director/ingreso-trabajo/SeleccionTipo.vue'
import SeleccionDeadline from '@/components/director/ingreso-trabajo/SeleccionDeadline.vue'

export default {
  name: 'IngresoTrabajo',
  components: {
    BlackModal,
    BackButton,
    SubmitButton,
    SeleccionRecurso,
    SeleccionCliente,
    SeleccionPalabrasClave,
    SeleccionDificultad,
    SeleccionDeadline,
    SeleccionAdmin,
    SeleccionTipo
  },

  data() {
    return {
      loading: false,
      showSuccessModal: false,
    }
  },

  computed: {
    ...mapGetters({
      recurso: 'ingresoTrabajo/recurso',
      cliente: 'ingresoTrabajo/cliente',
      palabraClave: 'ingresoTrabajo/palabraClave',
      subPalabraClave: 'ingresoTrabajo/subPalabraClave',
      dificultad: 'ingresoTrabajo/dificultad',
      deadline: 'ingresoTrabajo/deadline',
      admin: 'ingresoTrabajo/admin',
      tipo: 'ingresoTrabajo/tipo'
    }),

    formIsFilled() {
      return this.recurso && 
        this.cliente && 
        this.palabraClave &&
        this.subPalabraClave &&
        this.dificultad && 
        this.deadline && 
        this.admin &&
        this.tipo
    }
  },

  methods: {
    ...mapActions({ addWork: 'works/add' }),

    ...mapMutations({
      setRecurso: 'ingresoTrabajo/setRecurso',
      setCliente: 'ingresoTrabajo/setCliente',
      setPalabraClave: 'ingresoTrabajo/setPalabraClave',
      setDificultad: 'ingresoTrabajo/setDificultad',
      setDeadline: 'ingresoTrabajo/setDeadline',
      setAdmin: 'ingresoTrabajo/setAdmin',
      setTipo: 'ingresoTrabajo/setTipo'
    }),

    async submitIngresoTrabajo() {
      this.loading = true
      const { success } = await this.addWork({
        keyword: this.palabraClave,
        keyword2: this.subPalabraClave,
        deadline: this.deadline,
        idUser: this.recurso.id,
        idClient: this.cliente.id,
        idAdmin: this.admin.id,
        idDifficulty: this.dificultad.id,
        idType: this.tipo.id
      })

      if (success) {
        this.showSuccessModal = true;
      }

      this.loading = false
    },

    finalize() {
      this.buildAndOpenMail();
      this.cleanState();
      this.$router.push({ name: 'Director' });
    },

    buildAndOpenMail() {
      const mailTo = this.recurso.mail;
      const mailSubject = `${this.cliente.name}/${this.palabraClave}/${this.subPalabraClave}`;
      const mailBody = `Tenés un nuevo trabajo en tu bandeja.\n DEADLINE: ${this.deadline.toLocaleDateString('es-AR')}.\n DESCRIPCIÓN: `;
      const href = `mailto:${mailTo}?subject=${mailSubject}&body=${mailBody}`
      const linkToMail = document.createElement('a');
      linkToMail.href = href;
      linkToMail.target = "_blank";
      linkToMail.click();
    },

    cleanState() {
      this.setRecurso(null);
      this.setCliente(null);
      this.setPalabraClave({ property: 'palabraClave', value: null });
      this.setPalabraClave({ property: 'subPalabraClave', value: null });
      this.setDificultad(null);
      this.setDeadline(null);
      this.setAdmin(null);
      this.setTipo(null);
    },
  }
}
</script>
